@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  margin: 0;
  font-size: 16px;
  color: #444444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  margin: 0;
  padding: 0;
}

p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin: 0 0 18px 0;
  font-size: 16px;
  color: #888888;
}

a {
  color: #444444;
  text-decoration: none;
}

a:visited {
  color: #444444;
  text-decoration: none;
}

a:hover {
  color: #444444;
  text-decoration: underline;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 32px;
  margin: 24px;
  padding: 0;
  color: #000000;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 24px 0 24px 0;
  padding: 0;
  clear: both;
}

footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dddfdf;
  border-top: 1px solid #b0bbbb;  
  color: #444444;
  padding: 0;
  bottom: 0;
  text-align: center;
}

footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

footer ul li {
  font-size: 14px;
  display: inline-block;
  margin: 4px 10px 4px 10px;
}

section {
  padding: 10px 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header a {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 15px;  
  color:#505050;
  border: none;
  background-color: #c0c8c8;
  height: 30px;
  border-radius: 10px;
  padding: 4px 8px;
  cursor: pointer;
}

header a:hover {
  text-decoration: none;
  color:#000000;
}

header li#logo a {
  background: none;
  border: 0;
}

button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 15px;  
  color:#404040;
  border: none;
  background-color: #c0c8c8;
  height: 30px;
  border-radius: 10px;
  padding: 4px 8px;
  cursor: pointer;
}

button:disabled {
  color:#808080;
  cursor: default;
}

header {
  background-color: #f4f8f8;
  height: 49px;
  font-size: 12px;
  color: #000000;
  border-bottom: 1px solid #e4e8e8;
  text-align: right;
}

header ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

header li {
  display: inline-block;
  white-space: nowrap;
  margin: 10px 10px 0 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

header li#logo {
  display: block;
  margin: 2px 0 -16px 10px;
  float: left;
}

header li#logo img {
  height: 54px;
}

.cards article {
  display: inline-block;
  margin: 10px;
  padding: 10px;
  border: 1px solid #e4e8e8;
  border-radius: 10px;
  background-color: #f4f8f8;
  width: 300px;
  text-align: center;
}

div#toolbar {
  height: 33px;
  font-weight: 600;
}

div#toolbar span.back {
  cursor: pointer;
  display: inline-block;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  margin-right: 8px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

th {
  text-align: left;
  vertical-align: top;
  padding: 2px 8px;
}

td {
  padding: 2px 8px;
  vertical-align: top;
}

div[data-amplify-container] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

button.amplify-tabs__item {
  -moz-border-radius: 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
  border-radius: 10px 10px 0px 0px; 
  margin-right: 1px;
  color:#808080;
  border: none;
  border-bottom: 1px solid #ffffff;
  background-color: #d0d8d8;
}

button.amplify-tabs__item--active {
  color:#404040;
  border: none;
  border-bottom: 1px solid #c0c8c8;
  background-color: #c0c8c8;
}

div.amplify-tabs__panel {
  display: none;
  padding: 10px;
  border: 4px solid #c0c8c8;
  border-radius: 0px 10px 10px 10px;
  background-color: #c0c8c8;
}

div.amplify-tabs__panel--active {
  display: block;
}

fieldset {
  border-radius: 10px;
  border: none;
  background-color: #ffffff;
  margin: 0 0 10px 0;
}

form button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 15px;  
  color:#ffffff;
  border: none;
  background-color: #404848;
  height: 30px;
  border-radius: 10px;
  padding: 4px 8px;
  margin: 0;
  cursor: pointer;
}

form input {
  width: 300px;
  font-size: 20px;
  margin: 0 10px 10px 0;
  padding: 4px;
}

form label {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;  
  color:#404040;
}

.amplify-visually-hidden {
  display: none;
}

.amplify-flex {
  display: flex;
  flex-direction: column;
  justify-content:left;
}

.amplify-field-group {
  flex-direction: row;
}

.amplify-button {
  margin-bottom: 10px;
}